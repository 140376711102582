import React from "react";
import "./ImageCarousel.scss";
import edison from "../../../images/carousel-images/Edison.png";
import google from "../../../images/carousel-images/google.png";
import Honda from "../../../images/carousel-images/Honda.png";
import Lakers from "../../../images/carousel-images/Lakers.png";
import snap from "../../../images/carousel-images/snap.png";
import subaru from "../../../images/carousel-images/subaru.png";
import tinder from "../../../images/carousel-images/tinder.png";
import verizon from "../../../images/carousel-images/Verizon.png";
import dollarShaveClub from "../../../images/carousel-images/DollarShaveClub.png";
import usc from "../../../images/carousel-images/USC.png";

function TextCarousel() {
  const serviceContent = [
    { id: 0, title: "Edison", photo: edison, width: "118px", height: "32px" },
    { id: 1, title: "Google", photo: google, width: "119px", height: "40px" },
    { id: 2, title: "Honda", photo: Honda, width: "152px", height: "20px" },
    { id: 3, title: "Lakers", photo: Lakers, width: "82px", height: "48px" },
    { id: 4, title: "snap", photo: snap, width: "48px", height: "48px" },
    { id: 5, title: "subaru", photo: subaru, width: "177px", height: "26px" },
    { id: 6, title: "tinder", photo: tinder, width: "142px", height: "32px" },
    { id: 7, title: "verizon", photo: verizon, width: "140px", height: "32px" },
    {
      id: 8,
      title: "dollarshaveclub",
      photo: dollarShaveClub,
      width: "88px",
      height: "48px",
    },
    { id: 9, title: "usc", photo: usc, width: "96px", height: "32px" },
  ];

  return (
    <div className="carousel-container">
      <div className="logos">
        {/* <div className="logos-slide"> */}
        {/* render service content twice in order to prevent there being a white gap in the carousel
         * if you have rendered once than extra space will be added as items are move from intial
         * position to the end
         */}

        {/* 
          {serviceContent.map((service) => {
            return (
              <div key={service.id}>
                <img
                  src={service.photo}
                  alt="sponsors"
                  key={service.id}
                  width={service.width}
                  height={service.height}
                />
                <span className="border-right" />
              </div>
            );
          })}
        </div> */}

        <div className="logos-slide">
          {serviceContent.map((service) => {
            return (
              <div key={service.id}>
                <img
                  src={service.photo}
                  alt="sponsors"
                  key={service.id}
                  width={service.width}
                  height={service.height}
                  id={`${service.title}-image`}
                />
                <span className="border-right" />
              </div>
            );
          })}
        </div>
        <div className="logos-slide">
          {serviceContent.map((service) => {
            return (
              <div key={service.id}>
                <img
                  src={service.photo}
                  alt="sponsors"
                  key={service.id}
                  width={service.width}
                  height={service.height}
                  id={`${service.title}-image`}
                />
                <span className="border-right" />
              </div>
            );
          })}
        </div>

        <div className="logos-slide">
          {serviceContent.map((service) => {
            return (
              <div key={service.id}>
                <img
                  src={service.photo}
                  alt="sponsors"
                  key={service.id}
                  width={service.width}
                  height={service.height}
                />
                <span className="border-right" />
              </div>
            );
          })}
        </div>
      </div>
      <div className="carousel-mask" />
    </div>
  );
}

export default TextCarousel;
