/* eslint-disable */
import React, { useRef, useEffect, useState } from "react";
import "./BallEffect.scss";

// Importing necessary modules from matter-js for physics simulation
import {
  Engine,
  Render,
  Bodies,
  World,
  Composite,
  Runner,
  Mouse,
  MouseConstraint,
  Body,
  Events,
  Vector,
} from "matter-js";

function BallEffect() {

  const [ballEffectWidth, setballEffectWidth] = useState<number>(0)
  // Reference to the scene div element
  const scene = useRef<HTMLDivElement | null>(null);

  // Creating a physics engine instance
  const engine = useRef(Engine.create());

  // State to manage the radius of the cursor effect
  const [cursorRadius, setCursorRadius] = useState<number>(30); // Radius of the cursor effect
  const thickness = 150; // Thickness of the shapes (not used in the provided code)

  // Define the size of the shapes (letters)
  const shapeSize = 20;
  const tLineWidth = 7; // Line width for the letter "T"
  const xLineWidth = 7; // Line width for the letter "X"

  // Define a base color for letters
  const letterColor = "#424545";

  // Variables to control the drop area (normalized values from 0 to 1)
  const dropArea = { x: 0, y: 0, width: 0.1, height: 0.05 };

  // Radius of the exclusion zone around the cursor
  const exclusionRadius = 25; // Adjusted for a smaller cursor effect
  const pushStrength = 0.1; // Reduced value for less dramatic movement

  // Function to convert normalized values to actual pixel values
  const getPixelValue = (value: number, dimension: number): number =>
    value * dimension;

  // Define a function to create a letter
  /**
   * Creates a letter body based on the given parameters.
   * @param letter - The letter to create.
   * @param x - The x-coordinate of the letter.
   * @param y - The y-coordinate of the letter.
   * @param scale - The scale of the letter (default is 1).
   * @returns The created letter body.
   */
  const createLetter = (
    letter: string,
    x: number,
    y: number,
    scale: number = 1
  ): Body => {
    const size = shapeSize * scale; // Calculate the size of the letter based on the scale
    const lineWidth = letter === "T" ? tLineWidth : xLineWidth; // Determine the line width based on the letter

    if (letter === "T") {
      const width = size;
      const height = size * 1.4;
      const horizontalBarThickness = lineWidth;
      const verticalBarThickness = lineWidth;
      const horizontal = Bodies.rectangle(
        x,
        y - height / 2 + horizontalBarThickness / 2,
        width,
        horizontalBarThickness,
        { render: { fillStyle: letterColor }, isStatic: false }
      );
      const vertical = Bodies.rectangle(
        x,
        y,
        verticalBarThickness,
        height - horizontalBarThickness,
        { render: { fillStyle: letterColor }, isStatic: false }
      );
      return Body.create({
        parts: [horizontal, vertical],
        frictionAir: 0.1,
        friction: 0.1, // Increased friction
        restitution: 0, // No bouncing
      });
    } else if (letter === "X") {
      const thickness = lineWidth;
      const diagonal1 = Bodies.rectangle(x, y, size * 1.4, thickness, {
        angle: Math.PI / 4,
        render: { fillStyle: letterColor },
        isStatic: false,
      });
      const diagonal2 = Bodies.rectangle(x, y, size * 1.4, thickness, {
        angle: -Math.PI / 4,
        render: { fillStyle: letterColor },
        isStatic: false,
      });
      return Body.create({
        parts: [diagonal1, diagonal2],
        frictionAir: 0.1,
        friction: 0.1, // Increased friction
        restitution: 0, // No bouncing
      });
    } else if (letter === "A") {
      const height = size * 1.4;
      const width = size;
      const thickness = lineWidth;
      const leftLeg = Bodies.rectangle(
        x - width / 2 + thickness / 2,
        y + height / 4,
        thickness,
        height,
        { render: { fillStyle: letterColor }, isStatic: false }
      );
      const rightLeg = Bodies.rectangle(
        x + width / 2 - thickness / 2,
        y + height / 4,
        thickness,
        height,
        { render: { fillStyle: letterColor }, isStatic: false }
      );
      const horizontalBar = Bodies.rectangle(
        x,
        y - height / 4,
        width - thickness,
        thickness,
        { render: { fillStyle: letterColor }, isStatic: false }
      );
      return Body.create({
        parts: [leftLeg, rightLeg, horizontalBar],
        frictionAir: 0.1,
        friction: 0.1,
        restitution: 0,
      });
    } else if (letter === "B") {
      const height = size * 1.4;
      const width = size;
      const thickness = lineWidth;
      const verticalBar = Bodies.rectangle(
        x - width / 2 + thickness / 2,
        y,
        thickness,
        height,
        { render: { fillStyle: letterColor }, isStatic: false }
      );
      const topCircle = Bodies.circle(x, y - height / 4, width / 4, {
        render: { fillStyle: letterColor },
        isStatic: false,
      });
      const bottomCircle = Bodies.circle(x, y + height / 4, width / 4, {
        render: { fillStyle: letterColor },
        isStatic: false,
      });
      return Body.create({
        parts: [verticalBar, topCircle, bottomCircle],
        frictionAir: 0.1,
        friction: 0.1,
        restitution: 0,
        render: { fillStyle: letterColor },
        isStatic: false,
      });
    } else if (letter === "L") {
      const width = size;
      const height = size * 1.4;
      const thickness = lineWidth;
      const verticalBar = Bodies.rectangle(
        x - width / 2 + thickness / 2,
        y,
        thickness,
        height,
        { render: { fillStyle: letterColor }, isStatic: false }
      );
      const horizontalBar = Bodies.rectangle(
        x,
        y + height / 2 - thickness / 2,
        width,
        thickness,
        { render: { fillStyle: letterColor }, isStatic: false }
      );
      return Body.create({
        parts: [verticalBar, horizontalBar],
        frictionAir: 0.1,
        friction: 0.1,
        restitution: 0,
      });
    }
    // Default return for the TypeScript compiler
    return Bodies.rectangle(x, y, size, size);
  };

  // Function to create letters
  /**
   * Creates an array of Body objects representing letters based on the given parameters.
   *
   * @param numT The number of "T" letters to create.
   * @param numX The number of "X" letters to create.
   * @param numA The number of "A" letters to create.
   * @param numB The number of "B" letters to create.
   * @param numL The number of "L" letters to create.
   * @param dropArea The drop area object containing the x, y, width, and height values.
   * @returns An array of Body objects representing the created letters.
   */
  const createLetters = (
    numT: number,
    numX: number,
    numA: number,
    numB: number,
    numL: number,
    dropArea: { x: number; y: number; width: number; height: number }
  ): Body[] => {
    const cw = scene.current?.clientWidth || 0;
    const ch = scene.current?.clientHeight || 0;
    const elements: Body[] = [];
    // Define the initial position based on the drop area
    const initialX = getPixelValue(dropArea.x, cw);
    const initialY = getPixelValue(dropArea.y, ch);
    const dropWidth = getPixelValue(dropArea.width, cw);
    const dropHeight = getPixelValue(dropArea.height, ch);
    for (let i = 0; i < numT; i += 1) {
      const x = initialX + Math.random() * dropWidth - dropWidth / 2;
      const y = initialY + Math.random() * dropHeight - dropHeight / 2;
      elements.push(createLetter("T", x, y));
    }
    for (let i = 0; i < numX; i += 1) {
      const x = initialX + Math.random() * dropWidth - dropWidth / 2;
      const y = initialY + Math.random() * dropHeight - dropHeight / 2;
      elements.push(createLetter("X", x, y));
    }
    for (let i = 0; i < numA; i += 1) {
      const x = initialX + Math.random() * dropWidth - dropWidth / 2;
      const y = initialY + Math.random() * dropHeight - dropHeight / 2;
      elements.push(createLetter("A", x, y));
    }
    for (let i = 0; i < numB; i += 1) {
      const x = initialX + Math.random() * dropWidth - dropWidth / 2;
      const y = initialY + Math.random() * dropHeight - dropHeight / 2;
      elements.push(createLetter("B", x, y));
    }
    for (let i = 0; i < numL; i += 1) {
      const x = initialX + Math.random() * dropWidth - dropWidth / 2;
      const y = initialY + Math.random() * dropHeight - dropHeight / 2;
      elements.push(createLetter("L", x, y));
    }
    return elements;
  };

  useEffect(() => {
    const cw = scene.current?.clientWidth || 0;
    const ch = scene.current?.clientHeight || 0;
    engine.current.world.gravity.x = 1;
    const render = Render.create({
      element: scene.current as HTMLElement,
      engine: engine.current,
      options: {
        width: cw,
        height: ch,
        wireframes: false,
         background: "transparent",
      },
    });
    const mouse = Mouse.create(render.canvas);
    const mouseConstraint = MouseConstraint.create(engine.current, {
      mouse: mouse,
      constraint: {
        stiffness: 0.2,
        damping: 0.2,
        render: {
          visible: false,
        },
      },
    });
    // Define walls
    const walls = [
      Bodies.rectangle(cw / 2, -thickness / 2, cw + 2 * thickness, thickness, {
        isStatic: true,
        render: { visible: false },
      }),
      Bodies.rectangle(
        cw / 2,
        ch + thickness / 2,
        cw + 2 * thickness,
        thickness,
        { isStatic: true, render: { visible: false } }
      ),
      Bodies.rectangle(-thickness / 2, ch / 2, thickness, ch + 2 * thickness, {
        isStatic: true,
        render: { visible: false },
      }),
      Bodies.rectangle(
        cw + thickness / 2,
        ch / 2,
        thickness,
        ch + 2 * thickness,
        { isStatic: true, render: { visible: false } }
      ),
    ];
    const elements = createLetters(25, 15, 10, 10, 10, dropArea); // Adjust the counts as needed
    Composite.add(engine.current.world, [
      ...walls,
      mouseConstraint,
      ...elements,
    ]);
    Render.run(render);
    const runner = Runner.create();
    Runner.run(runner, engine.current);
    // Handle resizing
    const handleResizer = () => {
      if (render.canvas) {
        render.canvas.width = scene.current?.clientWidth || 0;
        render.canvas.height = scene.current?.clientHeight || 0;
      }
    };
    window.addEventListener("resize", handleResizer);
    handleResizer();

    // Create a visual representation of the cursor effect
    const cursorEffect = Bodies.circle(0, 0, cursorRadius, {
      isStatic: true,
      render: {
        fillStyle: "rgba(255, 255, 255, 0.1)", // Semi-transparent color
        visible: false,
      },
    });
    Composite.add(engine.current.world, cursorEffect);
    // Track mouse movement
    Events.on(mouseConstraint, "mousemove", function (event) {
      const { x, y } = event.mouse.position;

      // console.log("x: ", x, "y: ", y);

      const updatedY = y - 70 < 0 ? 0 : y - 70; // Adjusted for the rotation
      // const updatedX = x + 5 > 250 ? 270 : x + 5; // Adjusted for the rotation
      const updatedX = x + 10;

      // console.log("updatedY: ", updatedY);

      // Update cursor effect position
      Body.setPosition(cursorEffect, { x: updatedX, y: updatedY });
      Composite.allBodies(engine.current.world).forEach((body) => {
        if (body !== mouseConstraint.body) {
          const distance = Vector.magnitude(
            Vector.sub(body.position, { x: updatedX, y: updatedY })
          );
          if (distance < exclusionRadius) {
            // Calculate push direction and apply force
            const direction = Vector.normalise(
              Vector.sub(body.position, { x: updatedX, y: updatedY })
            );
            const pushMagnitude =
              pushStrength * (1 - distance / exclusionRadius);
            Body.applyForce(body, body.position, {
              x: direction.x * pushMagnitude,
              y: direction.y * pushMagnitude,
            });
          } else {
            // Apply damping for smoothness
            Body.setVelocity(body, {
              x: body.velocity.x * 0.888,
              y: body.velocity.y * 0.888,
            });
          }
        }
      });
    });
    return () => {
      Render.stop(render);
      World.clear(engine.current.world, false);
      Engine.clear(engine.current);
      render.canvas?.remove();
      //   render?.canvas = null;
      //   render?.context = null;
    };
  }, []);
  useEffect(() => {
    const handleResize = () => {
      const viewportWidth = window.innerWidth;
      console.log("Viewport width:", viewportWidth);

      const defaultWidth = (380 / viewportWidth) * 100;
      setballEffectWidth(defaultWidth)
    };
  
    // Set initial width
    handleResize();
  
    // Add event listener for resize
    window.addEventListener('resize', handleResize);
  
    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
// 
  return <div 
  className="letters-effect-container"
   ref={scene} />;
}
export default BallEffect;
