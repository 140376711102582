import React, { useEffect, useState } from "react";
import "./homeHero2.scss";
import techguru from "../../../assets/GuyCodingVid.mp4";

function HomeHero2() {
  const [isSafari, setIsSafari] = useState(false);

  useEffect(() => {
    // Check if the browser is Safari by examining the user agent string
    const isSafariBrowser = /^((?!chrome|android).)*safari/i.test(
      navigator.userAgent,
    );
    console.log(isSafariBrowser);
    setIsSafari(isSafariBrowser);
  }, []); // Empty dependency array to run this once on component mounts

  return (
    <div className="hero-container">
      <div className="hero-main">
        <div className="hero-video">
          <video
            className="iframe"
            src={techguru}
            playsInline
            autoPlay
            loop
            muted
          >
            Your browser does not support the video tag
          </video>
          <div className="video-overlay" /> {/* Add this div for overlay */}
        </div>
      </div>
      <div className={`hero-sub-container ${isSafari ? "safari-only" : ""}`}>
        <div className="hero-sub-container-header">
          Tech Solutions with social impact
        </div>
        <div className="hero-sub-container-sub-header">
          We drive client success and empower youth. Based in South Central Los
          Angeles, we build strong communities and promote diversity in tech
          with comprehensive services.
        </div>
      </div>
    </div>
  );
}

export default HomeHero2;
